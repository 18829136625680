@import '~antd/dist/antd.css';

@media (min-width: 1440px){
    .container {
      max-width: 1440px;
    }
    .container-fluid {
        padding-right: 60px;
        padding-left: 60px;
    }
}

@media (max-width: 1440px){
  .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
  }
  .sidebarmenu li a{
    font-size: 20px;
  }
  .header .header-in .header-menu li a span {
    display: block;
    font-size: 18px;
  }
  .header .header-in .header-menu li a i {
    font-size: 24px;
    width: 42px;
    height: 42px;
  }
  .body-wrapper {
    padding-top: 100px;
  }
}