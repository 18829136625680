*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
a {
  color: #0F6FC1;
  text-decoration: none;
  background-color: transparent;
}
small, .small {
  font-size: 80%;
  font-weight: lighter;
}
body{
	color: #7D7E80;
	background: #ffffff;
  font-size: 16px;
  padding: 0px!important;
  font-family: 'Open Sans', sans-serif;
  position: relative;
}
h1{
  font-size: 40px;
}
h2{
  font-size: 36px;
}
ul,h1,h2,h3,h4,h5,h6,p{
  margin-bottom: 0px;
}

ul li{
	list-style: none;
}
img{
	max-width: 100%;
}
a:hover {
  color: #0F6FC1;
}
a{
	text-decoration: none!important;
	transition: .3s ease;
}

.btn-primary {
  color: #fff;
  background-color: #0f6fc1;
  border-color: #0f6fc1;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0f6fc1;
  border-color: #0f6fc1;
}








.header {
  padding: 15px 0;
  position: fixed;
  box-shadow: 0px 1px 10px #aba4a447;
  z-index: 1000;
  width: 100%;
  background: #ffffff;
}
.header .header-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .header-in .header-menu {
  display: flex;
  align-items: center;
}
.header .header-in .header-menu li {
  margin-left: 50px;
}
.header .header-in .header-menu li a {
  display: block;
  text-align: center;
}
.header .header-in .header-menu li a i {
  border: 2px solid #0f6fc1;
  border-radius: 50%;
  font-size: 34px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: .3s ease;
}
.header .header-in .header-menu li a:hover i{
  background-color: #0f6fc1;
  color: #ffffff;
}
.header .header-in .header-menu li a span {
  display: block;
  font-size: 22px;
}
.login-left {
  display: flex;
  align-items: center;
}
.login-left img {
  margin-right: 10px;
}
.login-left .loginleft-content h5 {
  font-size: 25px;
  font-weight: 600;
  color: #000000;
}
.login-left .loginleft-content p {
  font-size: 25px;
  color: #000000;
}
.footer {
  padding: 20px 0;
  position: fixed;
  text-align: center;
  box-shadow: 0px 1px 10px #aba4a447;
  width: 100%;
  z-index: 101;
  bottom: 0;
  background: #ffffff;
}
.footer p{
  color: #0F6FC1;
  font-size: 20px;
  margin-bottom: 0;
}


.login-block {
  height: 100%;
  position: relative;
  padding: 170px 0;
}
.login-form {
  max-width: 695px;
  margin: auto;
  border: 2px solid #0F6FC1;
  padding: 25px 60px 80px 60px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #80808069;
}
.login-form h2{
  font-size: 42px;
  color: #000000;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.login-form .form-flex-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-form label, label {
  color: #0f6fc1;
  font-size: 20px;
  margin-bottom: 5px;
}
.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 12px;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0f6fc1;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::placeholder{
  color: #0f6fc1;
}
.btn {
  padding: 12px;
  font-size: 18px;
}
.login-block .login-form button{
  width: 100%;
}
.body-wrapper {
  position: relative;
  width: 100%;
  padding-top: 115px;
}
.sidebarmenu{
  position: fixed;
  height: 100vh;
  z-index: 100;
  width: 20%;
  background: #ffffff;
  transition: .3s ease-out;
  box-shadow: 0px 1px 10px #aba4a447;
  padding-top: 30px;
}
.body-rightbar {
  padding: 20px;
  transition: .3s ease;
  margin-left: 20%;
  min-height: 100%;
  display: block;
  padding-bottom: 160px;
  background-color: #f7f7f7;
}
.sidebarmenu li a {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid;
  font-size: 26px;
}
.sidebarmenu li a.isactive ,.sidebarmenu li a:hover{
  background-color: #0F6FC1;
  color: #ffffff;
}
.sidebarmenu li a i{
  margin-right: 15px;
  transition: .3s ease;
}
.sidebarmenu li a:hover i{
  transform: translate(10px , 0);
}
.add-manager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.modal-addmanager .modal-title{
  color: #000000;
  font-weight: 600;
}
.add-manager button i {
  margin-right: 10px;
}
.modal-addmanager .login-form {
  max-width: 100%;
  margin: auto;
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-addmanager .modal-content{
  padding: 0 30px;
}
.modal-addmanager .form-group-flexend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-addmanager .form-group-flexend  button{
  margin-left: 20px;
}
.table-addmanager .table thead {
  background: #0f6fc1;
}

.table-addmanager .table td, .table-addmanager  th {
  text-align: center;
}
.table-addmanager .table thead th {
  vertical-align: bottom;
  border-bottom: none;
  color: #ffffff;
}
.table-addmanager .table td, .table-addmanager th {
  text-align: center;
  color: #7D7E80;
  border-top: none;
  font-size: 18px;
}
.table-addmanager .table td.table-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-addmanager .table td.table-btn i {
  margin: 0 10px;
  color: #0f6fc1;
  font-size: 20px;
  cursor: pointer;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: #e9f2f9;
}
.table-addmanager {
  padding: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1411764705882353);
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.page-link{
  color: #0f6fc1;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #0f6fc1;
  border: 1px solid #0f6fc1;
  border-radius: .25rem;
}
.generate-key .input-group .form-control {
  border-right: none;
}
.bg-none {
  background: transparent;
  border-left: none!important;
}
.generate-key .input-group a{
  color: #0f6fc1;
}
.copy-btn{
  color: #0f6fc1;
  cursor: pointer;
}
.generate-key .input-group a,.generate-key .input-group i {

  font-size: 20px;
}
.generate-key .input-group .form-control {
  border-right: none!important;
  outline: none;
  box-shadow: none;
  border: 1px solid #0f6fc1;
}
.generate-key .form-group small {
  color: #FF0000;
  font-weight: 500;
}
.generate-key .row {
  align-items: center;
}
.form-group-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.generate-key  .or-text {
  color: #0F6FC1;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}
.Startsession-block {
  position: relative;
  text-align: center;
  background: black;
  border: 2px solid #FF0000;
  border-radius: 10px;
}
.Startsession-block .share-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.Startsession-block .share-btn img{
  margin-right: 10px;
}
/*=============================
            LOADER
===============================*/
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 10% auto;
  color: transparent;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




